<template>
  <v-card :ref="id" class="mx-auto my-1">
    <v-img class="white--text align-end" :src="banner"> </v-img>
    <v-card-title>{{ name }}</v-card-title>

    <v-card-subtitle class="pb-0">
      On: {{ date.toDate().toLocaleString() }}
    </v-card-subtitle>
    <v-card-subtitle class="pt-1" v-if="locationName">
      At: {{ locationName }}
    </v-card-subtitle>

    <v-card-text class="text--primary">
      {{ description }}
    </v-card-text>

    <v-card-actions>
      <v-btn color="blue" text v-if="link" :href="link">
        Link
      </v-btn>
      <v-spacer></v-spacer>
      <span v-if="over" class="mr-3">
        <v-img :src="require('@/assets/age.svg')" width="30px"></v-img>
      </span>
      <span class="title">
        | +{{ points
        }}<v-icon class="pb-1" color="blue">mdi-cards-diamond</v-icon>
      </span>
      <span class="title">
        | {{ membersGoingChosenEvent.length }}
        <v-icon class="pb-1" color="green">mdi-account-group</v-icon>
      </span>
    </v-card-actions>

    <v-divider></v-divider>

    <v-row v-if="going">
      <v-col
        v-if="!this.meGoing.chosen || this.meGoing.going"
        class="d-inline-flex justify-center"
      >
        <v-btn :color="this.meGoing.chosen ? 'success' : ''" text @click="go">
          <v-icon>mdi-checkbox-marked-circle-outline</v-icon>
          <span>Going</span>
        </v-btn>
      </v-col>
      <v-col
        v-if="!this.meGoing.chosen || !this.meGoing.going"
        class="d-inline-flex justify-center"
      >
        <v-btn :color="this.meGoing.chosen ? 'error' : ''" text @click="notGo">
          <v-icon>mdi-cancel</v-icon>
          <span>Not Going</span>
        </v-btn>
      </v-col>
    </v-row>

    <v-row dense v-if="attendance">
      <v-col>
        <List title="Attendance" :members="membersAttendedChosenEvent" />
      </v-col>
    </v-row>
    <v-divider></v-divider>

    <v-row dense v-if="goingCount">
      <v-col>
        <List title="Going" :members="membersGoingChosenEvent" />
      </v-col>
    </v-row>
    <v-divider></v-divider>

    <v-row dense v-if="goingCount">
      <v-col>
        <List title="Not Going" :members="membersNotGoingChosenEvent" />
      </v-col>
    </v-row>

    <v-fab-transition v-if="del">
      <v-btn small fab absolute top right color="error" @click="delAll">
        <v-icon>mdi-delete</v-icon>
      </v-btn>
    </v-fab-transition>
  </v-card>
</template>

<script>
import { db } from "@/plugins/firebase";
import store from "@/store";
import List from "@/components/Event/List";

export default {
  fiery: true,
  components: {
    List
  },
  props: {
    attendance: {
      type: Boolean,
      default: false
    },
    id: { type: String, required: true },
    delFunc: { type: Function, default: () => {} },
    del: { type: Boolean, default: false },
    banner: { type: String, required: true },
    date: { required: true },
    description: { type: String, required: true },
    location: {},
    locationName: { type: String, required: true },
    name: { type: String, required: true },
    link: { type: String, required: false },
    points: { type: Number, required: true },
    over: { type: Boolean, required: true },
    going: { type: Boolean, default: false },
    goingCount: { type: Boolean, default: false }
  },
  data() {
    return {
      attendees: this.attendance
        ? this.$fiery(db.collection("Attendance"), {
            key: "id",
            sub: {
              EventsAttended: {
                query: events => events,
                key: "id",
                map: true
              }
            }
          })
        : [],
      goers: this.$fiery(
        db
          .collection("ExpectedAttendance")
          .doc(this.id)
          .collection("Members"),
        {
          key: "id"
        }
      ),
      meGoing: this.going
        ? this.$fiery(
            db
              .collection("ExpectedAttendance")
              .doc(this.id)
              .collection("Members")
              .doc(store.state.user.Login),
            {
              key: "id"
            }
          )
        : [],
      members: this.attendance
        ? this.$fiery(db.collection("Members"), {
            key: "id",
            map: true
          })
        : []
    };
  },
  computed: {
    membersAttendedChosenEvent() {
      // find all members that have attended and event with the same id as the event
      return this.attendees.filter(member => member.EventsAttended[this.id]);
    },
    membersGoingChosenEvent() {
      // find all members that have attended and event with the same id as the event
      return this.goers.filter(member => member.chosen && member.going);
    },
    membersNotGoingChosenEvent() {
      // find all members that have attended and event with the same id as the event
      return this.goers.filter(member => member.chosen && !member.going);
    }
  },
  methods: {
    delAll() {
      if (this.del) {
        this.membersAttendedChosenEvent.forEach(member => {
          this.$fiery.remove(member.EventsAttended[this.id]);
        });
        this.delFunc();
      }
    },
    go() {
      if (this.meGoing.chosen) {
        this.meGoing.chosen = false;
        this.meGoing.going = false;
      } else {
        this.meGoing.chosen = true;
        this.meGoing.going = true;
      }
      this.$fiery.save(this.meGoing);
    },
    notGo() {
      if (this.meGoing.chosen) {
        this.meGoing.chosen = false;
      } else {
        this.meGoing.chosen = true;
        this.meGoing.going = false;
      }
      this.$fiery.save(this.meGoing);
    }
  }
};
</script>
