<template>
  <v-list dense class="py-0">
    <v-list-group>
      <template v-slot:activator>
        <v-list-item-content>
          <v-list-item-title>
            <div class="headline">{{ title }}: {{ members.length }}</div>
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-action v-if="DownloadCSV.length">
          <download-csv :data="DownloadCSV">
            <v-btn icon rounded>
              <v-icon>mdi-download</v-icon>
            </v-btn>
          </download-csv>
        </v-list-item-action>
      </template>

      <v-divider></v-divider>

      <v-list-item v-for="member in attendee" :key="member.id">
        <v-list-item-content>
          <v-row>
            <v-col cols="2" class="pr-0 d-inline-flex justify-center">
              <Avatar :user="member" :size="35" />
            </v-col>
            <v-col class="pl-0 text-left title">
              {{ member.id }}
            </v-col>
          </v-row>
          <v-divider></v-divider>
        </v-list-item-content>
      </v-list-item>
    </v-list-group>
  </v-list>
</template>

<script>
import Avatar from "@/components/Profile/Avatar";
import DownloadCsv from "vue-json-csv";
import { db } from "@/plugins/firebase";

export default {
  fiery: true,
  props: { members: { required: true }, title: { required: true } },
  components: {
    Avatar,
    DownloadCsv,
  },
  data() {
    return {
      attendees: this.$fiery(db.collection("Attendance"), {
        key: "id",
        map: true,
      }),
      mems: this.$fiery(db.collection("Members"), {
        key: "id",
        map: true,
      }),
    };
  },
  computed: {
    attendee() {
      return this.members.map((member) => {
        if (member) {
          let attended = this.attendees[member.id];
          if (!attended) {
            db.collection("Attendance").doc(member.id).set(
              {
                // Fields starting with CAPS should not be changed in the app
                Login: member.id,
              },
              { merge: true }
            );
            return { id: "Loading" };
          }
          return attended;
        } else {
          return { id: "Loading" };
        }
      });
    },
    DownloadCSV() {
      let info = [];
      this.members.forEach((member) => {
        if (this.mems[member.id]) {
          let mem = this.mems[member.id];
          mem.DoBReadable = mem.DoB.toDate().toLocaleDateString();
          delete mem[".uid"];
          info.push(mem);
        }
      });
      return info;
    },
  },
};
</script>
