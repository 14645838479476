<template>
  <v-container fluid>
    <h1>{{ title }}:</h1>
    <v-row v-if="events.length > 0">
      <v-col
        cols="12"
        md="6"
        lg="4"
        v-for="(event, index) in events"
        :key="index"
      >
        <Event
          :del="del"
          :delFunc="
            () => {
              storage.child('events/' + event.id).delete();
              $fiery.remove(event);
            }
          "
          :banner="event.banner"
          :date="event.date"
          :description="event.description"
          :location="event.location"
          :locationName="event.locationName"
          :name="event.name"
          :link="event.link"
          :id="event.id"
          :attendance="attendance"
          :points="event.points"
          :over="event.over18"
          :going="going"
          :goingCount="goingCount"
        ></Event>
      </v-col>
    </v-row>
    <v-row v-else class="my-5">
      <v-col cols="12" class="justify-center d-inline-flex">
        <v-icon size="200px">mdi-calendar-blank</v-icon>
      </v-col>
      <v-col cols="12" class="justify-center d-inline-flex">
        <h3 style="color: gray">
          Nothing here yet. Please come back later
        </h3>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { db, storage } from "@/plugins/firebase";
import Event from "@/components/Event/Event";

export default {
  props: {
    del: {
      type: Boolean,
      default: false,
    },
    attendance: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "All our upcoming events",
    },
    query: {
      type: Function,
      default: (event) =>
        event
          .where(
            "date",
            ">",
            new Date(new Date().setDate(new Date().getDate() - 1))
          )
          .orderBy("date"),
    },
    going: { type: Boolean, default: false },
    goingCount: { type: Boolean, default: false },
  },
  fiery: true,
  components: { Event },
  data() {
    return {
      storage: storage,
      events: this.$fiery(db.collection("Events"), {
        // start date of event is bigger than yesterday
        query: this.query,
        key: "id",
      }),
    };
  },
};
</script>
